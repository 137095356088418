// DASHBOARD.SCSS

.jumbotron-oncology.dashboard {
  position: relative;
  margin-top: 0px;
  padding: 0px;
  background-size: cover;
  border-radius: 0px;
  background-image: url("https://learning-platform-oncology.s3.amazonaws.com/images/onc-dash-1.jpg");

  .input-group-searchbar{
    min-width: 300px;
    font-size: 14px;
  }
  .container {
    // overflow: hidden;
    height: 100%;
    padding: 0px;
    background: transparent;

    .row {
      margin: 0px;

      .prompt {
        background-color: rgba($white, 0.7);
        padding: 40px 20px;

        h1 {
          color: black;
          font-family: Helvetica;
          margin: 0px;
          font-size: 28px;

          small {
            font-size: 18px;
            line-height: 40px;
          }
        }

        .description {
          color: black;
          font-family: "Roboto Light", Helvetica, sans-serif;
          font-size: 15px;
          margin-top: 12px;
        }

        h2 {
          color: black;
          font-family: "Roboto Medium", Helvetica, sans-serif;
          font-size: 16px;
        }

        .btn {
          width: 203px;
          font-size: 14px;
          font-family: "Roboto Slab Light", Georgia, serif;
          margin: 6px;
        }
      }
      .search {
        background: transparent;
        padding: 0px;
      }
    }
  }
}

.dashboard-oncology {
  padding: 0px;

  .time-to-grow{
    .card{
      height: 100%;
    }
  }

  .whats-new,
  .time-to-grow,
  .getting-started,
  .resource-page{
    padding-bottom: 40px;
    padding-left: 0px;
    padding-right: 0px;

    .card-img-top {
      height: 160px;
      object-fit: cover;
    }

    h2 {
      font-family: Helvetica;
      font-size: 24px;
      margin-bottom: 16px;
    }

    h3 {
      font-family: "Roboto Slab Regular", Georgia, serif;
      font-size: 24px;
    }

    .card {
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

      .card-body {
        h5 {
          font-family: "Roboto Bold", Helvetica, sans-serif;
          font-size: 16px;
          margin-bottom: 4px;

          a {
            color: $grey-text;
            text-decoration: none;
            word-break: normal;

            &:hover {
              color: lighten($grey-text, 20%);
            }
          }
        }

        .card-text {
          font-family: "Roboto Regular", Helvetica, sans-serif;
          font-size: 14px;
          margin-bottom: 14px;
          margin-top: 14px;
          // color: $grey-mid-dark;
        }
      }

      .card-footer {
        background-color: $white;
        border-top: none;

        a.btn {
          font-size: 14px;
          padding: 1px 16px 2px 16px;
        }
      }
    }
  }

  .whats-new,
  .resource-page {
    .card {
      height: 100%;

      .card-body {
        margin-bottom: 0px;
        padding-bottom: 0px;
        padding-top: 14px;
      }
      .card-footer {
        height: 40px;
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 16px;
      }
    }
  }

  .time-to-grow{
    background-color:rgba($light-blue, .3);


    h1{
      color: black;
      opacity: 1;
    }
  }

  .learning-course-titles{
    h2{
      font-size: 30px;
      font-weight: 700;
      color: #98002D ;
    }
  }
.learning-plan-bg:nth-child(even) {
  background-color: #F9EFF2;

}


  .blossoms-collected-wrapper{
    position: relative;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
  }

  .blossoms-collected-text{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px;

    h2{
      margin: 0;
      color: black;
      font-weight: 550;
    }

    p{
      margin: 0;
      color: $grey-text
    }
  }
  .blossoms-collected-blossoms{
    display: flex;
    align-items: center;
    font-size: 22px;
    padding: 14px 14px 14px 50px;

    .left-text{
      display: flex;
      flex-direction: column;
      line-height: 14px;
      padding-top: 5px;
      padding-left: 10px;
    }

    svg.flower-icon{
      width: 50px;
      height: 50px;
    }
  }

  .next-up{
    background-color: $light-blue;
    position: absolute;
    top: 20px;
    color: white;
    padding: 5px 40px 1px 15px;

    h3{
      font-family: Helvetica;
      font-weight: 550;
      padding: 0;
      margin: 0;
    }
  }

  .getting-started {
    // background-color: $white;

    .container {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }

    img {
      width: 160px;
    }

    .card-body {
      padding-bottom: 0px;
    }

    .card-footer {
      margin-left: 176px;
      padding-top: 0px;
      padding-bottom: 20px;
    }
  }

  .recommended-learning {
    background: #F7F7F7;

    .description {
      // font-weight: 300;
      font-family: "Roboto Light", Helvetica, sans-serif;
      font-size: 14px;
    }
  }

  .learning-plan-summary {
    background-color: $white;

    .container {
      .btn.view {
        // font-family: 'Roboto Slab Light', Georgia, serif;
      }

      h2 {
        font-family: "Roboto Slab Light", Georgia, serif;
        font-size: 24px;
      }

      .description {
        // font-weight: 300;
        font-family: "Roboto Light", Helvetica, sans-serif;
        font-size: 14px;
      }

      .card {
        border: none;

        h5,
        h5 a {
          color: $grey-text;
          font-family: "Roboto Slab Light", Georgia, serif;

          .badge.badge-blue {
            font-family: "Roboto Light", Helvetica, sans-serif;
            background-color: $aon-blue;
            color: $white;
          }
        }

        h5 a {
          &:hover {
            color: lighten($grey-text, 20%);
            text-decoration: none;
          }
        }

        .list-group {
          img {
            height: 50px;
            width: 50px;
          }

          .list-group-item {
            padding: 10px 0px;
            border: none;

            &:hover,
            &:active {
              bborder: none;
              background-color: $white;
            }

            p {
              margin-bottom: 0px;
              line-height: inherit;
              word-break: normal;
              // font-weight: 300;
              font-family: "Roboto Regular", Helvetica, sans-serif;
              font-size: 14px;
            }
          }
        }

        .card-empty {
          text-align: center;

          img {
            width: 50%;
            margin: 10px 10px 16px 10px;
          }

          p {
            font-family: "Roboto Regular", Helvetica, sans-serif;
            font-size: 13px;
            color: $grey-mid-dark;
            margin: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .jumbotron.dashboard {
    .container {
      .row {
        .prompt {
          .btn {
            width: 165px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .dashboard {
    .getting-started {
      .card-text {
        margin-top: 30px;
      }
      .card-footer {
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .learning-plan-summary {
    .container {
      .card {
        h5 {
          font-size: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .dashboard {
    .getting-started {
      img {
        width: 100%;
        display: block;
        margin-right: 0px;
      }
      .card-title {
        width: 100%;
        display: inline-block;
        margin-right: 0px;
        margin-top: 20px;
      }
      .card-text {
        margin-top: 00px;
      }
      .card-footer {
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard {
    .whats-new,
    .learning-plan-summary {
      .card-deck-wrapper {
        margin-right: 0;
        margin-left: 0;

        .card-deck {
          display: block;
          width: 100%;
          margin-bottom: 0.75rem;
          table-layout: fixed;
          border-spacing: 1.25rem 0;
          margin: 0px;

          .card {
            display: block;
            margin: 20px 0px;
            vertical-align: top;
          }
        }
      }
    }

    .learning-plan-summary {
      .container {
        padding: 20px !important;
      }
    }
    .getting-started {
      .container {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .jumbotron.dashboard {
    .container {
      .row {
        .prompt {
          .btn {
            width: 100%;
            margin: 6px 0px;
          }
        }
      }
    }
  }

  .dashboard {
    .whats-new {
      margin: 0px 20px;

      @media screen and (max-width: 575px) {
        margin: 0px;
        padding: 0px 20px;
      }
    }
  }
}





.dashboard-journey{
  background-color: white;

  .journey-title{
     font-family: Helvetica;
      color: black;
  }

}



