// RESOURCES-ONCOLOGY.SCSS

.pink-num{
  color: #FBADC5;
  font-family: Georgia;
  font-size: 100px;
  margin-top: -50px;
}

.red-text{
  color: $dark-red;
}

.question-time-activity{
  display: flex;
  margin-top: 0;

  @media screen and (max-width: 991px) {
    display: block;
  }
}

.lt-lightblue-background {
  background-color: rgba($light-blue, .1);
  margin-right: 0;
  margin-left: 0;
}
.lt-darkblue-background {
  background-color: rgba($dark-blue, .25);
  margin-right: 0;
  margin-left: 0;
}
.ltblue-background-extended {
  background-color: rgba($blue, .1);
  margin-right: -50px;
}
.blue-background-extended {
   background-color: $blue;
   margin-right: -50px;
    color: $white;
 }

.fadedred-background {
  background-color: rgba($faded-red, .75);
  margin-right: 0;
  margin-left: 0;
  padding: 20px;
}

.one-thirty-background {
  background-color: #f3e5ea;

  .inner-fade {
    position: relative;
    background-color: #e7cfd7;
    border-right: 10px solid $dark-red;

    &::after {
      content: "";
      position: absolute;
      width:0;
      height:0;
      top: calc(50% - 5px);
      right: -30px;
      border: 10px solid;
      border-top-color: transparent;
      border-left-color: $dark-red;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }
}

.icon-with-text {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;
  align-items: start;

  p{
    margin-top: 0;
    margin-bottom: 5px;
  }

  img {
    height: 75px;

  }
}
.red-horizontal-line {
  border-top: 3px solid $dark-red;
}

.blue-bg-imgs{
  position: absolute;
  padding-top: 90px;
  width: 100%;
  padding-right: 50px;
}

.blue-bg-japan{
  position: absolute;
  padding-top: 90px;
  width: 70%;
}

.rx-table{
  .row{
    display: flex;
    justify-content: space-between;
    padding: 7px 15px;

  }
  .row:nth-child(2n-1){
    background-color: rgba($light-blue, .1);
  }
  .row > div:first-child{
    max-width: 80%;
  }
  button {
    color: $white;
    background-color: $blue;
    font-size: 14px;
    height: 26px;
    width:80px;
    border: none;
    border-radius: 5px;
  }
  button:hover{
    background-color: $light-blue;
  }
}

h3 {
  color: $dark-red;
}

.icon-circle-svg {
  width: 35px;
  max-width: 35px;
  min-width: 35px;
}

.bullets-with-background-image {
  .background {
    position: absolute;
    top: 0;
    right: 30px;
    z-index: 0;
    height: 100%;
  }
}

.grid-patient-population {
  position: relative;
  display: grid;
  grid-template-columns: 40px 2fr 1fr;
  margin: 1rem 0;
  color: $gray1;
}

.bordered-box {
  margin: 0 0 3rem;
  padding: 20px;
  border: 1px solid $dark-red;
  border-radius: 9px;

  h3 {
    color: $almost-black;
    font-weight: normal;
  }

  &.grey-border {
    border: 1px solid $gray1;
  }
}

.background-gray {
  position: relative;
  margin: 0;
  padding: 20px;
  background-color: $box-gray;

  .supersized-number {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -20px;
    font-size: 250px;
    line-height: 250px;
    font-weight: bolder;
    color: $dark-red;
    opacity: 0.10;
    z-index: 0;
  }
}

.accordion {
  margin: 0 0 1rem;
  //border-bottom: 65px solid $faded-red;

  .accordion-banner {
    width: 100%;
    margin: 0 0 1rem;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: $faded-red;


    .accordion-title {
      font-size: 26px;
      line-height: normal;
      font-weight: bold;
      color: $almost-black;
    }

    .accordion-nav {
      .btn {
        border-radius: 0;
      }
      .btn-red:not(.collapsed),
      .btn[aria-expanded="true"] {
        pointer-events: none;
        color: $dark-red;
        background-color: $white;
        border-color: $dark-red;
      }
    }
  }

  .card {
    border: none;

    .card-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

.risk-assessment {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr 55px) 1fr;
  grid-template-rows: repeat(2, auto);
  gap: 0 0;
  color: $gray1;

  .content-area {
    padding: 20px
  }
  .arrow-one,
  .arrow-two {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 15px;

    img {
      width: 40px;
      height: 27px;
    }
  }

  .area-one {
    grid-area: 1 / 1 / 2 / 2;
    background-color: $blue-risk-dark;
  }
  .arrow-one { grid-area: 1 / 2 / 2 / 3; }
  .area-two {
    grid-area: 1 / 3 / 2 / 4;
    background-color: $blue-risk-med;
  }
  .arrow-two { grid-area: 1 / 4 / 2 / 5; }
  .area-three {
    grid-area: 1 / 5 / 2 / 6;
    background-color: $blue-risk-light;
  }
  .area-four {
    grid-area: 2 / 1 / 3 / 6;
    position: relative;
    text-align: center;

    &.arrow-three {
      padding-top: 0;

      img {;
        margin: 0 auto;
        width: 80%;

        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto 55px) auto;

    .arrow-one,
    .arrow-two {
      justify-content: center;
      padding-right: 0;
      padding-bottom: 15px;

      img {
        transform: rotate(90deg);
      }
    }

    .area-four {
      display: flex;
      justify-content: center;
      align-items: center;

      &.arrow-three {
        padding-top: 20px;
        padding-left: 0;

        img {;
          width: 100%;
        }
      }

      .mobile-box {
        padding: 20px;
        background-color: $white;
        border-top: 1px dotted $dark-blue;
        border-bottom: 1px dotted $dark-blue;
      }
    }

    .area-one { grid-area: 1 / 1 / 2 / 2; }
    .arrow-one { grid-area: 2 / 1 / 3 / 2; }
    .area-two { grid-area: 3 / 1 / 4 / 2; }
    .arrow-two { grid-area: 4 / 1 / 5 / 2; }
    .area-three { grid-area: 5 / 1 / 6 / 2; }
    .area-four { grid-area: 1 / 2 / 6 / 3; }
  }
}

.two-five-one {
  background-size: 85%;
  background-position: bottom right;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    background-size: 100%;
    background-position: center right;
  }
}

.two-five-two {
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    background-size: 30%;
    background-position: center right;
  }
}

.three-five-one,
.three-five-six {
  background-size: 45%;
  background-position: center right;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    background-size: 0;
    background-image: none;
  }
}

.three-five-five {
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
}

.three-five-six {
  @media screen and (min-width: 992px) {
    background-size: contain
  }
}

.three-five-eleven {
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
}
