// COLORS.SCSS

$black: #000;
$almost-black: #0a0b0b;
$white: #fff;

$blue: #005baa;
$dark-blue: #023f88;
$grey-blue: #e5eff0;
$lt-blue: rgba($blue, .1);
$light-blue: #00B4ED;
$blue-risk-dark: #a9dff5;
$blue-risk-med: #bee8f8;
$blue-risk-light: #d4effa;

$dark-red: #98002D;
$blossom-red: #e34c4c;
$faded-red: #f2dfe5;

$black-link: #262836;
$grey-text: #333;
$grey-dark: #4d4f53;
$grey-mid-dark: #818283;
$grey-mid-light: #aaa;
$grey-light: #dddcdd;
$grey-bg: #efefef;
$form-grey: #b8b8b8;

$gray1: #46435e;
$box-gray: #f9f9f9;

$aon-red: #e11b22;
$aon-red-dark: #cb181f;
$aon-blue: #5eb6e4;
$aon-blue-dark: #0b2f69;
$aon-teal: #008daf;
$aon-yellow: #eca934;
$aon-purple: #5d2b73;
$aon-green: #74c144;
$aon-bluegreen: #20b9b2;

$sage-green: #4B816E;

$bootstrap-grey: #ced4da;

$simBest: #379c52;
$simGood: #fff500;
$simPoor: #e11b22;
