// JOURNAL-ONCOLOGY.SCSS

.journal {
  .custom-notes {
    a.journal-path {
      width: 100%;
      display: flex;
      margin: 0;
      padding: 10px;
      font-size: 16px;
      line-height: normal;
      color: $black-link !important;
      border: 1px solid $dark-red;
      border-radius: 5px;
      cursor: pointer;

      &:hover,
      &.active {
        border-color: $blossom-red;
        text-decoration: none;
      }
      &.active {
        background-color: lighten($blossom-red, 30%);
      }
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 20px;
    border: 1px solid $blossom-red;
    border-radius: 5px;
    width: 100%;
  }
}

.question-time-wrapper {
  padding: 0 50px;
  background-color: $white;
}

.question-time {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 15px;
  border-top: 2px solid $dark-red;
  border-bottom: 2px solid $dark-red;

  svg {
    width: 52px;
    min-width: 52px;
    height: 58px;
  }

  .titles {
    margin-left: 15px;

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    .title {
      font-size: 50px;
      font-weight: bold;
      line-height: 1;
      color: $dark-red;
    }

    .subtitle {
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      color: $black-link;
    }
  }
}
