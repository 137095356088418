.course-nav-top{
  background-color: $dark-red;
  height: 50px;
  padding-left: 50px;
  padding-right: 50px;
  display: grid;
  grid-template-columns: auto 150px;
  align-items: center;


  .course-nav-titles{
    color: white;
    display: flex;
  }

  .journey-title{
    padding-left: 20px;
    padding-right: 20px;
  }

  .resource-title{
    padding-left: 20px;
    font-weight: 600;
  }


  .course-nav-right{
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;

    svg.icon{
      width: 34px;
      height: 34px;

      circle {
        fill: $dark-red;
      }

      &:hover {
        circle {
          fill: lighten($dark-red, 5%);
        }
      }
    }
  }
}
