label.radio-data{
  display: flex;
  cursor: pointer;

  input[type="radio"], {
    appearance: none;
    background-color: white;
    margin: 0;
    font: inherit;
    color: $dark-red;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    border: 1px solid gray;
    transform: translateY(-0.075em);
    place-content: center;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr;
    margin-right: 10px;

    &:focus,
    &:hover {
      border: 1px solid gray;
    }

    &::before {
      content: "";
      height: 22px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }

    &:checked {
      border-color: $dark-red;
    }

    &:checked::before {
      width: 28px;
      height: 28px;
      transform: scale(1);
    }

    &:disabled {
      color: gray;
      cursor: not-allowed;
    }
  }
}

// radios
label.radio-data {
  margin: 1rem 0;
  padding: .25rem 0;

  input[type="radio"] {
    border-radius: 50%;

    &::before {
      border-radius: 50%;
      box-shadow: inset 2em 2em $dark-red;
    }
  }
}
